$( document ).ready(function() {
    $('.change_details.user-details').on('click', function() {
        $(this).closest('.user_default').hide();        
        $('#user-details').show();
    });
    $('.js-close-user-details').on('click', function() {
        $('#user-details-default').show();        
        $('#user-details').hide();
    });

    
    $('.change_details.user-address').on('click', function() {
        $(this).closest('.user_default').hide();        
        $('#user-address').show();
    });
    $('#user-address .Addresses .address').on('click', function() {
        const address_line1 = $(this).find('.details .line1').text();
        const address_line2 = $(this).find('.details .line2').text();
        console.log('address_line1:', address_line1);
        console.log('address_line2:', address_line2);

        $('#user-address-default').find('h4').text(address_line1);
        $('#user-address-default').find('p').text(address_line2);

        $('#user-address-default').show()      
        $('#user-address').hide();
    });

    $('.open_address_form').on('click', function() {   
        $('#user-address').hide();
        $('#user-address-form').show();
    });

    $('.js-close-address-form').on('click', function() {   
        $('#user-address').show();
        $('#user-address-form').hide();
    });


    
    $('.change_details.delivery_slot').on('click', function() {
        $(this).closest('.user_default').hide();        
        $('#delivery_slot').show();
    });
    $('.js-close-time-slots').on('click', function() {
        $('#delivery_slot_default').show();        
        $('#delivery_slot').hide();
    });


    var delivery_slot = new Swiper("#delivery_slot .swiper", {
        direction: 'horizontal',
        loop: false,       
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        spaceBetween: 10,   
    });   

    
    $('.change_details.payment_method').on('click', function() {
        $(this).closest('.user_default').hide();        
        $('#payment_method').show();
    });
    $('.js-close-payments').on('click', function() {
        $('#payment_method_default').show();        
        $('#payment_method').hide();
    });
})