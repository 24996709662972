// Add unique class identifiers to each Swiper instance and its navigation buttons
const addSwiperClasses = (element, index) => {
    $(element).find('.swiper').addClass('s' + index);
    $(element).find('.swiper-button-next-custom').addClass('s' + index);
    $(element).find('.swiper-button-prev-custom').addClass('s' + index);
}

$( document ).ready(function() {

    // Product Slider
    $('.productslider').each(function (index) {
        addSwiperClasses(this, index)

        var productSlider = new Swiper(".productslider .swiper.s" + index, {
            direction: 'horizontal',
            loop: false,       
            observer: true,
            observeParents: true,
            slidesPerView: 2.05,
            spaceBetween: 10,     
            navigation: {
              nextEl: ".productslider .swiper-button-next-custom.s" + index,
              prevEl: ".productslider .swiper-button-prev-custom.s" + index,
            },
            breakpoints: {
                600: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                900: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1300: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1600: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                2000: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                },
            },
        });          
    });

    //Recipe Slider
    $('.recipeSlider').each(function (index) {
        addSwiperClasses(this, index)

        var recipeSlider = new Swiper(".recipeSlider .swiper.s" + index, {
            direction: 'horizontal',
            loop: false,       
            observer: true,
            observeParents: true,
            slidesPerView: 1.2,
            spaceBetween: 10,     
            navigation: {
              nextEl: ".recipeSlider .swiper-button-next-custom.s" + index,
              prevEl: ".recipeSlider .swiper-button-prev-custom.s" + index,
            },
            breakpoints: {
                600: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                900: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1300: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                2000: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
            },
        });          
    });
    

    //Featured Product Slider
    $('.featured_product_slider').each(function (index) {
        addSwiperClasses(this, index)

        var featured_product_slider = new Swiper(".featured_product_slider .swiper.s" + index, {
            direction: 'horizontal',
            loop: false,       
            observer: true,
            observeParents: true,
            slidesPerView: 2.05,
            spaceBetween: 10,     
            navigation: {
              nextEl: ".featured_product_slider .swiper-button-next-custom.s" + index,
              prevEl: ".featured_product_slider .swiper-button-prev-custom.s" + index,
            },
            breakpoints: {
                600: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1600: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                2000: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
            },
        });         
    }); 

    $('.explore_recipe_categories').each(function (index) {
        addSwiperClasses(this, index)

        var explore_categories_slider = new Swiper(".explore_recipe_categories .explore_categories .swiper.s" + index, {
            direction: 'horizontal',
            loop: false,       
            observer: true,
            observeParents: true,
            slidesPerView: 2.05,
            spaceBetween: 10,   
            breakpoints: {
                600: {
                    slidesPerView: 3.15,
                    spaceBetween: 10,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
            },
        });     
        
        var mobile_category_slider = new Swiper(".explore_recipe_categories .mobile_category_slider .swiper.s" + index, {
            direction: 'horizontal',
            loop: false,       
            observer: true,
            observeParents: true,
            slidesPerView: 'auto',
            spaceBetween: 10,   
        });    
    }); 

    var product_hero = new Swiper(".product_hero .product_images .swiper", {
        direction: 'horizontal',
        loop: false,       
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        spaceBetween: 0,     
        navigation: {
          nextEl: ".product_hero .product_images .swiper-button-next-custom",
          prevEl: ".product_hero .product_images .swiper-button-prev-custom",
        },
    }); 

    var related_recipeSlider = new Swiper(".related_recipe .swiper", {
        direction: 'horizontal',
        loop: false,       
        observer: true,
        observeParents: true,
        slidesPerView: 1.25,
        spaceBetween: 10,   
        breakpoints: {
            600: {
                slidesPerView: 2.05,
                spaceBetween: 10,
            },
            800: {
                slidesPerView: 3.05,
                spaceBetween: 10,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });    

    var product_reviews_slider = new Swiper(".product_reviews .swiper", {
        direction: 'horizontal',
        loop: false,       
        observer: true,
        observeParents: true,
        slidesPerView: 1.05,
        spaceBetween: 10,   
        breakpoints: {
            600: {
                slidesPerView: 1.5,
                spaceBetween: 20,
            },
            1200: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },  
        navigation: {
          nextEl: ".product_reviews .swiper-button-next-custom",
          prevEl: ".product_reviews .swiper-button-prev-custom",
        },
    }); 

    var article_grid_slider = new Swiper(".article_grid .swiper", {
        direction: 'horizontal',
        loop: false,       
        observer: true,
        observeParents: true,
        slidesPerView: 1.25,
        spaceBetween: 10,   
        breakpoints: {
            600: {
                slidesPerView: 2.25,
                spaceBetween: 20,
            }
        },  
    }); 

    $('.article_slider').each(function (index) {
        addSwiperClasses(this, index)
        var article_slider = new Swiper(".article_slider .swiper.s" + index, {
            direction: 'horizontal',
            loop: false,       
            observer: true,
            observeParents: true,
            slidesPerView: 1.05,
            spaceBetween: 10,     
            navigation: {
              nextEl: ".article_slider .swiper-button-next-custom.s" + index,
              prevEl: ".article_slider .swiper-button-prev-custom.s" + index,
            },
            breakpoints: {
                600: {
                    slidesPerView: 2.1,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1600: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                2100: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
            },
        });  
    }); 

    $('.interview_slider').each(function (index) {
        addSwiperClasses(this, index)
        var interview_slider = new Swiper(".interview_slider .swiper.s" + index, {
            direction: 'horizontal',
            loop: false,       
            observer: true,
            observeParents: true,
            slidesPerView: 1.05,
            spaceBetween: 10,     
            navigation: {
              nextEl: ".interview_slider .swiper-button-next-custom.s" + index,
              prevEl: ".interview_slider .swiper-button-prev-custom.s" + index,
            },
            breakpoints: {
                600: {
                    slidesPerView: 2.1,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1600: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                2100: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
        });  
    });

    var article_image_slider = new Swiper(".article_image_slider .swiper", {
        direction: 'horizontal',
        loop: false,       
        observer: true,
        observeParents: true,
        slidesPerView: 1.25,
        spaceBetween: 10,   
        breakpoints: {
            600: {
                slidesPerView: 1.25,
            }
        },  
    });

    $('.article_products_block').each(function (index) {
        addSwiperClasses(this, index)
        var article_products_block = new Swiper(".article_products_block .swiper.s" + index, {
            direction: 'horizontal',
            loop: false,       
            observer: true,
            observeParents: true,
            slidesPerView: 1.05,
            spaceBetween: 10,  
            breakpoints: {
                600: {
                    slidesPerView: 2.1,
                },
                1200: {
                    slidesPerView: 3,
                },
            },
        });  
    });

    var searchBar_categories = new Swiper(".searchBar_wrapper .swiper", {
        direction: 'horizontal',
        loop: false,       
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        spaceBetween: 10,  
        freeMode: true,
    }); 

    $('#js-increase-qty').on('click', function() {
        var qtyInput = $('#qty_of_prd');
        var currentValue = parseInt(qtyInput.val(), 10);

        if (currentValue < 100) {
            qtyInput.val(currentValue + 1);
        }
    });
    $('#js-decrease-qty').on('click', function() {
        var qtyInput = $('#qty_of_prd');
        var currentValue = parseInt(qtyInput.val(), 10);

        if (currentValue > 1) {
            qtyInput.val(currentValue - 1);
        }
    });

    $('.js-scroll-to-review').on('click', function() {
        var headerHeight = $('header').outerHeight() + 50;
    
        $('html, body').animate({
            scrollTop: $('#user_review').offset().top - headerHeight
        }, 1000); 
    });

    $('.footer_accordion .accordion-header').click(function() {
        $('.accordion-content').not($(this).next()).slideUp();
        $('.arrow').not($(this).children('.arrow')).removeClass('rotate');

        // Toggle the clicked accordion
        $(this).next().slideToggle();
        $(this).children('.arrow').toggleClass('rotate');
    });

    // Toggle .open class on .dropdown when .selected is clicked
    $('.mobile_tab_nav > .selected').on('click', function() {
        $('.mobile_tab_nav > .dropdown').toggleClass('open');
    });

    // Remove .open class if clicking outside of .mobile_tab_nav
    $(document).on('click', function(event) {
        if (!$(event.target).closest('.mobile_tab_nav').length) {
            $('.mobile_tab_nav > .dropdown').removeClass('open');
        }
    });

    //Filter Accordion
    $('.filterAccordion .accordion-header').click(function() {
        $(this).next('.accordion-content').slideToggle();
    
        $(this).find('.arrow').toggleClass('rotate');
    });
    $('.filterAccordion .accordion-content ul').each(function() {
        const listItems = $(this).find('li');
    
        if (listItems.length > 6) {
          listItems.slice(6).hide();
    
          $(this).append(`
            <li class="readMoreFilters active" style="display: block">
                <span>Show all</span>
            </li>
          `);
        }
    });
    $('.readMoreFilters').on('click', function() {
        const $this = $(this);
        const listItems = $this.siblings('li');

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            listItems.slideDown();
            $this.find('span').text('Show less');
        } else {
            $(this).addClass('active');
            listItems.slice(6).slideUp();
            $this.find('span').text('Show all');
        }
    });
    $('.filterAccordion .accordion-content ul li').on('click', function() {
        if(!$(this).hasClass('readMoreFilters')){
            $(this).toggleClass('active');
        }
    });

    $('.js-toggle-filter-sidebar').on('click', function() {
        $('.productFilters').addClass('open');
        setTimeout(function() {
            $('.productFilters .filterList').addClass('open');
        }, 50);
    });
    $('.js-close-filters').on('click', function() {
        $('.productFilters .filterList').removeClass('open');
        setTimeout(function() {
            $('.productFilters').removeClass('open');
        }, 200);
    });

    //Cart Functions
    $('.js-open-cart').click(function() {
        $('body').addClass('locked');
        $('.cart').addClass('open');
        setTimeout(function() {
            $('.cart_sidebar').addClass('open');
        }, 50);
        setTimeout(function() {
            $('.extra_products').addClass('open');
        }, 70);
    });

    $('.close_extra_sidebar').click(function() {
        $('.extra_products').removeClass('open');
    });
    $('.js-close-cart').click(function() {
        $('.extra_products').removeClass('open');
        $('.cart_sidebar').removeClass('open');
        setTimeout(function() {
            $('.cart').removeClass('open');
        }, 300);
        $('body').removeClass('locked');
    });
     

    //Search bar 
    let isInsideSearchWrapper = false;
    const searchBar = $('.searchbar > form > input')
    const searchKeyword = $('.searhc-input-val');
    const searchKeywordParent = searchKeyword.parent();
    searchKeywordParent.css('opacity', 0); 

    if($(window).width() >  1200){
        searchBar.focus(function() {
            $('.searchBar_overlay').addClass('active');
            $('.searchBar_wrapper').addClass('open');
        });
        searchBar.blur(function() {
            if (!isInsideSearchBarWrapper) {
                $('.searchBar_overlay').removeClass('active');
                $('.searchBar_wrapper').removeClass('open');
            }
        });
        $('.searchBar_wrapper .searchSuggestions').mousedown(function() {
            isInsideSearchBarWrapper = true;
        }).mouseup(function() {
            isInsideSearchBarWrapper = false;
        });
        $(document).mousedown(function(e) {
            if (!$(e.target).closest('.searchBar_wrapper .searchSuggestions, .searchbar > form > input').length) {
                $('.searchBar_overlay').removeClass('active');
                $('.searchBar_wrapper').removeClass('open');
            }
        });
        searchBar.on('input', function() {
            const inputValue = searchBar.val();  
            if (inputValue) {
                searchKeyword.text(inputValue);
                searchKeywordParent.css('opacity', 1); 
            } else {            
                searchKeyword.text('');
                searchKeywordParent.css('opacity', 0); 
            }
        });
    }else{
        searchBar.click(function() {
            $('.searchBar_wrapper').addClass('open');
        });
        $('.js-close-search').click(function() {
            $('.searchBar_wrapper').removeClass('open');
        });
        $('.searchSuggestions .topbar form input').on('input', function() {
            const inputValue = $(this).val();  
            if (inputValue) {
                searchKeyword.text(inputValue);
                searchKeywordParent.css('opacity', 1); 
            } else {            
                searchKeyword.text('');
                searchKeywordParent.css('opacity', 0); 
            }
        });
    }


    // Select all headings within the .privacy_page .text_content
    const headings = $(".privacy_page .text_content").find("h2, h3, h4, h5");
    
    // Select the ul inside .page_scroll_links where li's will be appended
    const $scrollLinks = $(".page_scroll_links ul");

    // Loop through the headings and add id attribute & create li's
    headings.each(function(index) {
        // Create unique id for each heading
        let headingId = 'heading-section-' + (index + 1);

        // Add the id to the current heading element
        $(this).attr('id', headingId);

        // Create li and a tag
        var $li = $("<li></li>");
        var $a = $("<a></a>").attr("href", "#" + headingId).text($(this).text());

        // Append a tag to li, then append li to the ul
        $li.append($a);
        $scrollLinks.append($li);
    });

    $(".page_scroll_links ul li a").on("click", function(event) {
        event.preventDefault();

        const target = $(this).attr("href");
        const targetPosition = $(target).offset().top - 160;

        $("html, body").animate({
            scrollTop: targetPosition
        }, 800); 
    });


    let timeout;
    // Hover on the trigger (#open-megamenu)
    $('#open-megamenu').hover(function() {
        clearTimeout(timeout); 
        $('.megamenu').addClass('open'); 
    }, function() {
        timeout = setTimeout(function() {
            $('.megamenu').removeClass('open');
        }, 500); 
    });

    // Hover on the megamenu itself
    $('.megamenu .menu').hover(function() {
        clearTimeout(timeout); 
        $('.megamenu').addClass('open');
    }, function() {
        timeout = setTimeout(function() {
            $('.megamenu').removeClass('open');
        }, 100); 
    });

    $('#open-megamenu, .megamenu').on('mouseenter', function() {
        clearTimeout(timeout); 
    });

    $('.js_trigger_password_change').click(function(e) {
        e.preventDefault();
        $('#change-password').addClass('hidden');
        $('#change-password-form').removeClass('hidden');
    });
    $('.js-hide-passowrd-form').click(function(e) {
        e.preventDefault();
        $('#change-password').removeClass('hidden');
        $('#change-password-form').addClass('hidden');
    });

    
    $('.js_trigger_number_change').click(function(e) {
        e.preventDefault();
        $('#change-phone').addClass('hidden');
        $('#change-phone-form').removeClass('hidden');
    });
    $('.js-hide-tel-form').click(function(e) {
        e.preventDefault();
        $('#change-phone').removeClass('hidden');
        $('#change-phone-form').addClass('hidden');
    });

    
    $('.js_trigger_email_change').click(function(e) {
        e.preventDefault();
        $('#change-email').addClass('hidden');
        $('#change-email-form').removeClass('hidden');
    });
    $('.js-hide-email-form').click(function(e) {
        e.preventDefault();
        $('#change-email').removeClass('hidden');
        $('#change-email-form').addClass('hidden');
    });

});

// Adjust the height of the featured block category image
const updateCategoryRangeBlockHeight = (index) => {
    if ($(window).width() > 900) {
        const swiperHeight = $(".featured_product_slider .swiper.s" + index + " .swiper-slide").height();
        $(".featured_product_slider .category_range .recipe_image").css('padding-top', swiperHeight);
    }else return
};
// Function to update the height
function updateVideoListHeight() {
    const featuredVideo = $('.featured_video');
    const videoList = $('.video_list');
    
    if (featuredVideo && videoList) {
      const featuredVideoHeight = featuredVideo.height();    
      if($(window).width() > 1200) { 
        videoList.css('height', `${featuredVideoHeight}px`);
      }else return
    }
  }


$(window).on("load resize",function(e){
    //Dynamically adjust image height on load and resize for featured product slider 
    $('.featured_product_slider').each(function (index) {
        updateCategoryRangeBlockHeight(index);
    });

    updateVideoListHeight();

    if($(window).width() >  1200){
        var inputWidth = $('.searchbar > form > input').width();
        $('.searchBar_wrapper .searchSuggestions').width(inputWidth);
    }

});


window.addEventListener('scroll', function() {
    const header = document.querySelector('header');
    if (window.scrollY > 50) {
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
    }
});